import withCache from "./../helpers/cache";
import myFetch from "./myFetch";

const getSearchParams = ({ page, search }) => {
  const pageQuery = page ? `page=${page}` : "";
  const searchQuery = search ? `search=${search}` : "";
  const queriesArr = [pageQuery, searchQuery].filter(Boolean);
  return queriesArr.length ? `/?${queriesArr.join("&")}` : "";
};

const loadExercises = (params) => {
  const queryString = getSearchParams(params);

  return myFetch(`/api/exercises${queryString || "/"}`);
};

const loadExercisesForCurrentUser = (params) => {
  const queryString = getSearchParams(params);

  return myFetch(`/api/exercises/current-user${queryString || "/"}`);
};

export const loadFavExercises = (params) => {
  const queryString = getSearchParams(params);

  return myFetch(`/api/exercises/fav${queryString || "/"}`);
};

export const loadFavExercisesWithCache = withCache(loadFavExercises);

export const loadExercisesWithCache = withCache(loadExercises);

export const loadExercisesForCurrentUserWithCache = withCache(
  loadExercisesForCurrentUser
);

export const addExercises = ({ body }) => {
  loadExercisesWithCache.invalidate();
  loadExercisesForCurrentUserWithCache.invalidate();
  return myFetch("/api/exercises/", { method: "POST", body: body });
};

export const editExercises = ({ body, id }) => {
  loadExercisesWithCache.invalidate();
  loadExercisesForCurrentUserWithCache.invalidate();
  return myFetch(`/api/exercises/${id}/`, {
    method: "PATCH",
    body: body,
  });
};

export const deleteExercises = ({ id }) => {
  loadExercisesWithCache.invalidate();
  loadExercisesForCurrentUserWithCache.invalidate();
  return myFetch(`/api/exercises/${id}/`, { method: "DELETE" }, null);
};
